<template>
  <v-container class="body-card">
    <v-row>
      <v-col cols="12" class="pb-0">
        <v-card 
            class="mx-auto mb-3 border-green">
            <v-row align="center" class="ma-3 mb-0">
                <v-col cols="12" class="text-center py-0 grey--text">
                  {{ detail.fleet_type_detail.name }} {{ $t("Ticket") }}
                </v-col>
            </v-row>
            <v-row align="center">
                <v-col cols="12" class="text-center py-0 primary--text">
                    <h1>{{detail.fare_name}}</h1>
                </v-col>
            </v-row>
            <v-row>
                <v-col class="my-0 py-0 text-center">
                    <div class="body-2 text-color-date font-weight-light">{{detail.date}}</div>
                </v-col>
            </v-row>
            <v-row>
                <v-col class="my-0 py-2 text-center">
                    <div class="font-invoice">{{detail.ticket}}</div>
                </v-col>
            </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="py-0">
        <v-card 
            class="mx-auto mb-3 border-green">
            <v-row align="center" class="mt-3 mb-0 mx-0 border-bottom">
                <v-col cols="12" class="py-0 black--text">
                    <h3>{{$t("TripInformation")}}</h3>
                    <div class="body-1 pb-3">
                      <span class="font-italic">{{$t("Time")}}</span> : {{detail.departureTime}}<br/>
                      <span class="font-italic">{{$t("Price")}}</span> : {{ Intl.NumberFormat('de-DE', { style: 'currency', currency: detail.currency_code || 'USD' }).format(detail.price) }} <br/>
                      <!-- <span class="font-italic">{{$t("Route")}}</span> : {{detail.routeCode}} - {{detail.routename}} <br/> -->
                      <!-- <span class="font-italic">{{$t("LicensePlates")}}</span> : {{detail.busName}} <br/> -->
                      <!-- <span class="font-italic">{{$t("DriversName")}}</span> : {{getDrivers()}} <br/> -->
                      <!-- <span class="font-italic">{{$t("FromTheStation")}}</span> : {{detail.originalName}} <br/> -->
                    </div>
                </v-col>
            </v-row>
            <!-- <v-row>
                <v-col class="my-0 py-2 pl-6">
                    <div class="caption text-color-date font-weight-light">{{$t("TicketAvailable")}} {{parseInt(detail.max_trip) - detail.used_ticket}}</div>
                </v-col>
            </v-row> -->
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="py-0">
        <v-card 
            class="mx-auto border-green">
            <v-row align="center" class="my-1">
                <v-col cols="12" class="px-6 py-1">
                    <p class="subtitle-1 font-weight-bold mb-0">
                      <span class="route-code caption">{{detail.routeCode}}</span> {{detail.routename}}
                    </p>
                    <div class="text-justify caption line-height-normal" v-for="(route, index) in detail.description" :key="index" cols="12">
                        <span v-if="index == 0" class="body-2 font-weight-bold pb-3">{{detail.originalName}} - {{detail.destinationName}}</span>
                        <span v-else class="body-2 font-weight-bold pb-3">{{detail.destinationName}} - {{detail.originalName}}</span>
                        <p class="detail-route text-justify mb-0 pb-3">
                        {{ route.stop_in }}
                        </p>
                    </div>
                </v-col>
            </v-row>
            <v-row>
                <v-col class="my-0 py-2 text-center">
                    <div class="body-1 text-color-route font-italic">{{$t("TicketAlreadyUsed")}}</div>
                </v-col>
            </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from "moment";
export default {
  name: "TripDetailMultidays",
  props: {
    detail: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      fare_rule: this.detail.fare_rule,
      validate_start_date: "",
      validate_end_date: "",
      validate_closed_time: "",
      validate_open_time: "",
      expire_after_usage: "",
    }
  },
  methods: {
    getDrivers() {
      var drivers = this.detail.driverName
      let uniqueDriver = drivers.filter((driver, index) => {
        return drivers.indexOf(driver) === index
      })
      return uniqueDriver.join(", ")
    },
  },
  created() {
    moment.locale(this.$i18n.locale)
  },
  mounted() {
    if (this.fare_rule.validate_start_date != undefined) {
      this.validate_start_date = moment.utc(this.fare_rule.validate_start_date).format("DD-MM-YYYY")
    } else {
      this.validate_start_date = ""
    }
    if (this.fare_rule.validate_end_date != undefined) {
      if (this.fare_rule.validate_end_date != '') {
        this.validate_end_date = moment.utc(this.fare_rule.validate_end_date).format("DD-MM-YYYY")
      } else {
        this.validate_end_date = ""
      }
    } else {
      this.validate_end_date = ""
    }
    if (this.fare_rule.validate_closed_time != undefined) {
      this.validate_closed_time = this.fare_rule.validate_closed_time
    } else {
      this.validate_closed_time = ""
    }
    if (this.fare_rule.validate_open_time != undefined) {
      this.validate_open_time = this.fare_rule.validate_open_time
    } else {
      this.validate_open_time = ""
    }
    if (this.fare_rule.expire_after_usage != undefined) {
      this.expire_after_usage = this.fare_rule.expire_after_usage
    } else {
      this.expire_after_usage = ""
    }
  }
}
</script>
<style lang="scss" scoped>
.body-card {
  background-color: #f5fdf7;
  height: 100%;
  width: 100%;
  padding-top: 0;
}

.font-invoice {
  font-size: 0.6rem !important;
}

.white-background {
  background: #fff;
}

.border-green {
  border: 1px solid #4BB14E;
}

.border-bottom {
  border-bottom: 1px solid #F5F5F5;
}

.line-height-normal {
  line-height: normal;
}

.text-color-route {
  color: #45a648;
}

.text-color-date {
  color: #489E4B;
}

.lowercase {
  text-transform: lowercase;
}

.route-code {
  border-radius: 50%;
  padding: 2px 2px;
  vertical-align: middle;
  text-align: center;
  color: #fff;
  background-color: #6397d7;
}
</style>